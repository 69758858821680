export const CRISP_FAQ = "https://aide.cfas.apprentissage.beta.gouv.fr/fr/";
export const SUPPORT_PAGE_ACCUEIL = "/contact";

export const GESTION_COMPTE_GROUP = `${SUPPORT_PAGE_ACCUEIL}`;
export const GESTION_ORGANISME_GROUP = `${SUPPORT_PAGE_ACCUEIL}`;
export const TRANSMISSION_DONNEES_GROUP = `${SUPPORT_PAGE_ACCUEIL}`;
export const EFFECTIFS_GROUP = `${SUPPORT_PAGE_ACCUEIL}`;
export const SIFA_GROUP = `${SUPPORT_PAGE_ACCUEIL}`;
export const AUTRE_GROUP = `${SUPPORT_PAGE_ACCUEIL}`;

export const GC_AIDE_CREATION_ELEMENT_LINK = `${GESTION_COMPTE_GROUP}`;
export const GC_SUPPRESSION_COMPTE_ELEMENT_LINK = ` ${GESTION_COMPTE_GROUP}`;
export const GC_AUTRE_ELEMENT_LINK = `${GESTION_COMPTE_GROUP}`;

export const GO_MODIFICATION_IDENTITE_ELEMENT_LINK = `${GESTION_ORGANISME_GROUP}`;
export const GO_MODIFICATION_RELATION_ELEMENT_LINK = ` ${GESTION_ORGANISME_GROUP}`;
export const GO_AUTRE_ELEMENT_LINK = `${GESTION_ORGANISME_GROUP}`;

export const TD_PARAMETRAGE_ELEMENT_LINK = `${TRANSMISSION_DONNEES_GROUP}`;
export const TD_API_ELEMENT_LINK = `${TRANSMISSION_DONNEES_GROUP}`;
export const TD_MANUEL_ELEMENT_LINK = `${TRANSMISSION_DONNEES_GROUP}`;
export const TD_COMPRENDRE_ELEMENT_LINK = `${TRANSMISSION_DONNEES_GROUP}`;
export const TD_AUTRE_ELEMENT_LINK = `${TRANSMISSION_DONNEES_GROUP}`;

export const EFF_ERREUR_ELEMENT_LINK = `${EFFECTIFS_GROUP}`;
export const EFF_AFFICHAGE_ELEMENT_LINK = `${EFFECTIFS_GROUP}`;
export const EFF_EDITION_ELEMENT_LINK = `${EFFECTIFS_GROUP}`;
export const EFF_AUTRE_ELEMENT_LINK = `${EFFECTIFS_GROUP}`;

export const SIFA_EFFECTIF_INCORRECT_ELEMENT_LINK = `${SIFA_GROUP}`;
export const SIFA_REJET_ELEMENT_LINK = `${SIFA_GROUP}`;
export const SIFA_AUTRE_ELEMENT_LINK = `${SIFA_GROUP}`;

export const AUTRE_AMELIORATION_ELEMENT_LINK = `${AUTRE_GROUP}`;
export const AUTRE_ANOMALIE_ELEMENT_LINK = `${AUTRE_GROUP}`;
export const AUTRE_MODIF_RESEAU_ELEMENT_LINK = `${AUTRE_GROUP}`;
export const AUTRE_AFFELNET_LINK = `${AUTRE_GROUP}`;
